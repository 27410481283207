.react-datepicker {
  font-family: SpaceGrotesk-Regular, sans-serif;
  font-size: 1rem;
  color: #41414a;
  border-radius: 0;
  border-width: 0px;
}
.react-datepicker__header {
  background-color: white;
  border-radius: 0;
}
.react-datepicker__week-number {
  font-size: 0.85rem;
  color: #bbb;
}
.react-datepicker__day-name,
.react-datepicker__day {
  color: #41414a;
}
.react-datepicker__day--outside-month {
  color: #bbb;
}
.react-datepicker__day--disabled {
  color: #eaeaea;
}

/* Styles for highlighted public holidays */
.react-datepicker__day--holidays,
.react-datepicker__day--holidays:hover {
  background-color: #f00 !important;
  color: white !important;
  border-radius: 50%;
}

/* Setting keyboard position to transparent because it is confusing */
.react-datepicker__day--keyboard-selected {
  background-color: #fff0;
  color: #000;
}
.react-datepicker__day--keyboard-selected:hover {
  background-color: #eee;
}

/* Set selected date color and make it round */
.react-datepicker__day--selected,
.react-datepicker__day--selected:hover,
.react-datepicker__day--highlighted,
.react-datepicker__day--highlighted:hover {
  background-color: #377a6c;
  color: #fff;
  border-radius: 50%;
}
.react-datepicker__day:hover {
  border-radius: 50%;
}

/* This hides the default month & year label.
   Instead of default header, month and year dropdowns are shown. */
.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown,
.react-datepicker__current-month.react-datepicker__current-month--hasMonthDropdown {
  display: none;
}

/* Styling year and month dropdowns */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #f5f5f5;
  font-size: 1rem;
  text-align: left;
  top: 10px;
}
.react-datepicker__year-dropdown {
  width: 40%;
  text-align: center;
}
.react-datepicker__month-option--selected_month,
.react-datepicker__year-option--selected_year {
  background-color: gray;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.react-datepicker__month-option--selected,
.react-datepicker__year-option--selected {
  color: #fff0;
}
.react-datepicker__month-option,
.react-datepicker__month-option:hover,
.react-datepicker__year-option,
.react-datepicker__year-option:hover {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  padding: 0.2rem 1rem;
}

/* Setting closed month and year dropdown fonts bigger */
.react-datepicker__month-read-view--selected-month,
.react-datepicker__year-read-view--selected-year {
  font-size: 1.4rem;
}

/* Hiding month and year dropdown arrows so font can be bigger without overflowing.
   Mostly needed in mobile view.
   And it also looks better without arrows. */
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
  display: none;
}

/* Replacing month and year navigation arrows with svg arrows */
.react-datepicker__navigation {
  color: #000;
  width: 24px;
  height: 24px;
  border: none !important; /* default arrows are made with borders */
}
button.react-datepicker__navigation--previous,
.react-datepicker__navigation--previous {
  background: url(../../../assets/arrow-left.svg) no-repeat;
}
.react-datepicker__navigation--next {
  background: url(../../../assets/arrow-right.svg) no-repeat;
}
.react-datepicker__navigation--years-upcoming {
  background: url(../../../assets/arrow-up.svg) no-repeat;
}
.react-datepicker__navigation--years-previous {
  background: url(../../../assets/arrow-down.svg) no-repeat;
  padding-bottom: 0.2rem;
}

/* Week picker styling
    - days horizontal margins to 0px and using padding instead so click region is continous between days.
    - bigger week number, color to black and day color to gray so it's more clear user is selecting a week.
    - on week hover -> highlight a week.
    - hiding day hovering.
*/

.week-picker
  > .react-datepicker__month-container
  > .react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day {
  color: #bbb;
  margin: 0px;
  padding: 0.166rem;
  border-radius: 0px;
}
.week-picker
  > .react-datepicker__month-container
  > .react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__week-number {
  color: #000;
  font-size: 1.2rem;
  margin: 0px; /* for avoiding un-clickable gaps between week number and monday */
  border-radius: 0px;
  padding: 0.3rem; /* increase clickable region */
}
.week-picker
  > .react-datepicker__month-container
  > .react-datepicker__month
  > .react-datepicker__week:hover {
  background-color: #d3d3d3;
}
.week-picker
  > .react-datepicker__month-container
  > .react-datepicker__month
  > .react-datepicker__week
  > .react-datepicker__day:hover {
  background-color: #fff0;
}
.react-datepicker__week-number--clickable:hover {
  background-color: #fff0 !important;
}

/* Styling selected week days */
.react-datepicker__day--of-selected-week {
  color: white !important;
  background-color: #377a6c;
}
.react-datepicker__day--of-selected-week:hover {
  background-color: #377a6c !important;
}
.react-datepicker__day-missed--highlighted,
.react-datepicker__day-missed--highlighted:hover {
  background-color: #ff0000b2 !important;
  color: white !important;
  border-radius: 50%;
}
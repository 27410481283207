html,
body {
  background-color: #f7f7f7;
  font-family: SpaceGrotesk-Regular, sans-serif;
  font-size: 16px;
  color: #41414a;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.38;
  margin: 0px;
}
a {
  color: #4373b5;
  text-decoration: none;
}
a:hover,
a:active {
  color: #719bd6;
}
.small-link {
  font-size: 0.85rem;
  margin-top: 0.2rem;
}

.menu-link {
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  margin-top: 1.3rem;
  color: #000000;
}

.desktop-menu-link {
  font-family: SpaceGrotesk-Medium, sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 1.3rem;
  margin-right: 1.5rem;
  color: #41414a;
}

.active-link {
  color: #719bd6;
}

button {
  font-family: SpaceGrotesk-Regular, sans-serif;
  font-size: 1rem;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

svg.rotate {
  animation-name: rotate;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@font-face {
  font-family: 'SpaceGrotesk';
  src: local('SpaceGrotesk'),
    url(./assets/fonts/SpaceGrotesk-VariableFont_wght.ttf) format('truetype');
}

@font-face {
  font-family: 'SpaceGrotesk-Medium';
  src: local('SpaceGrotesk-Medium'),
    url(./assets/fonts/SpaceGrotesk-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'SpaceGrotesk-Regular';
  src: local('SpaceGrotesk-Regular'),
    url(./assets/fonts/SpaceGrotesk-Regular.ttf) format('truetype');
}

    /* Override styling from global DateInput.css  */
  .responsive-calendar .react-datepicker__day-missed--highlighted,
  .responsive-calendar .react-datepicker__day-missed--highlighted:hover {
    background-color: #377a6c !important;
    color: white !important;
    border-radius: 50%;
  }
  
  .responsive-calendar .react-datepicker__day.custom-day-large,
  .responsive-calendar .react-datepicker__day-names .react-datepicker__day-name.custom-day-large   {
    margin: 12px;
    background-color: white;
  }
  
  .responsive-calendar .react-datepicker__day.custom-day-small,
  .responsive-calendar .react-datepicker__day-names .react-datepicker__day-name.custom-day-small {
    margin: 8px;
    background-color: white;
  }

  .responsive-calendar .react-datepicker__day.custom-day-very-large,
  .responsive-calendar .react-datepicker__day-names .react-datepicker__day-name.custom-day-very-large {
    margin: 12px 25px;
    background-color: white;
  }

  .react-datepicker__header.react-datepicker__header--custom {
    border:0;
  }
  
  .responsive-calendar .react-datepicker__day--outside-month {
    color: transparent;
    pointer-events: none;
  }
  
  .responsive-calendar .react-datepicker__day--outside-month > span {
    display:none;
  }
  
 